*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.voice-visualizer__progress-indicator {
  background-color: #777 !important;
}

.voice-visualizer__progress-indicator-time {
  display: none !important;
}

#do-insights-section {
  display: none !important;
}

#do-candidate-report-verdict {
  display: none !important;
}

:is(#menu-currentLocationCoursePage, #menu-countryCode, #menu-expYearCoursePage) {
  z-index: 9999;
}

#menu-currentLocationCoursePage {
  height: 360px;
}

#menu-expYearCoursePage {
  height: 270px;
}
